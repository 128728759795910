
import { defineComponent } from "vue";
import {mapState} from "vuex"
import { useToast } from "vue-toastification";

//components
import Loading from "@/components/loading/Loading.vue"
//services
import MultisigsServices from '@/services/MultisigsServices'
import AccountService from "@/services/AccountServices";
//models
export default defineComponent({
  name: "multisig-transactions",
  props:["actions"],
  components:{
    Loading 
  },

  data(){
    const toast = useToast();
    let account:any
    let proposer:any
    let proposalName:any
    return{
      loading: true,
      account,
      proposer,
      proposalName,
      toast
    }
  },

  computed:{
    ...mapState(['selectedAccount']),
  },
  
  methods:{
    async init(){
      this.loading = true
      try{
        this.account = (await AccountService.getSelectedAccount()).data
        this.proposer = this.$route.params.account
        this.proposalName = this.$route.params.name
      }catch(e){}
      this.loading = false
    },
    async Approve(){
      try {
        await MultisigsServices.approve(this.account.accountName,this.proposer,this.proposalName,this.account.permission)
        this.toast.success(`Transaction was successful.`) ;
      } catch (error) {
        console.log("error ===>" , error);
        this.toast.error(`${error}`)
      }
    },
    async Execute(){
      try {
        await MultisigsServices.execute(this.account.accountName,this.proposer,this.proposalName,this.account.permission)
        this.toast.success(`Transaction was successful.`) ;
      } catch (error) {
        console.log("error ===>" , error);
        this.toast.error(`${error}`)
      }
    },
    copy(){
      if(!this.account.accountName){
        this.toast.error("Please login!") 
      }else{
        this.$emit("copy" , true)
      }
    }
  },

  mounted(){
    this.init()
  },

  watch:{
    "selectedAccount.accountName": function(){
      this.init()
    }
  }

});
