import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProposalInfo = _resolveComponent("ProposalInfo")!
  const _component_MultisigTx = _resolveComponent("MultisigTx")!
  const _component_RequestedApproval = _resolveComponent("RequestedApproval")!
  const _component_CopyMultisig = _resolveComponent("CopyMultisig")!
  const _component_Loading = _resolveComponent("Loading")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ProposalInfo, {
          proposer: _ctx.proposal.proposer,
          proposalName: _ctx.proposal.proposalName
        }, null, 8, ["proposer", "proposalName"]),
        (!_ctx.copyFlag)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_MultisigTx, {
                onCopy: _ctx.setCopy,
                actions: _ctx.actions
              }, null, 8, ["onCopy", "actions"]),
              _createVNode(_component_RequestedApproval)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_CopyMultisig, {
                onReturnProposal: _ctx.setCopy,
                actions: _ctx.actions
              }, null, 8, ["onReturnProposal", "actions"])
            ]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_Loading)
      ]))
}