
import { defineComponent } from "vue";
//components
import Loading from '@/components/loading/Loading.vue'
import ProposalInfo from '@/pages/proposal/components/ProposalInfo.vue'
import MultisigTx from '@/pages/proposal/components/MultisigTx.vue'
import RequestedApproval from '@/pages/proposal/components/RequestedApproval.vue'
import CopyMultisig from '@/pages/proposal/components/CopyMultisig.vue'
//services
import MultisigsServices from '@/services/MultisigsServices'
//models

export default defineComponent({
  name: "proposal",

  components:{
    Loading,
    ProposalInfo,
    MultisigTx,
    RequestedApproval,
    CopyMultisig
  },

  data(){
    let proposal:any=[]
    let actions:any
    return{
      loading:true,
      proposal,
      copyFlag:false,
      actions,
    }
  },

  methods:{
    async init(){
      this.loading =true
      let scope:any= this.$route.params.account
      let name:any= this.$route.params.name
      try{
        this.proposal = await MultisigsServices.getSingleProposal(scope,name)
        this.actions = await MultisigsServices.getRowTransaction(scope,name)
      }catch(e){}
      this.loading = false
    },

    async getActions(){
      let scope:any= this.$route.params.account
      let name:any= this.$route.params.name
      this.actions = await MultisigsServices.getRowTransaction(scope,name)
    },


    setCopy(flag){
      this.copyFlag = flag
    }
  },

  mounted(){
    this.init()
  }

});
