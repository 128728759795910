
import { defineComponent } from "vue";
//components
import Loading from "@/components/loading/Loading.vue"
//services
import MultisigsServices from '@/services/MultisigsServices'
import Global from "@/services/GlobalServices";
import BpModel from "@/models/global/BpModel";
//models

export default defineComponent({
  name: "requested-approval",
  components:{
    Loading
  },
  data(){
    let approvals:any=[]
    let totalProvided:number = 0
    let totalRequest:number = 0
    let bps:BpModel[] = []
    let acceptedTop21:number[] = []
    return{
      loading: true,
      approvals,
      totalRequest,
      totalProvided,
      bps,
      acceptedTop21
    }
  },

  methods:{
    async init(){
      this.loading = true
      let scope:any= this.$route.params.account
      let name:any= this.$route.params.name
      try{
        let approvals = await MultisigsServices.getApprovals2(scope , name)
        this.totalRequest = approvals.totalRequest
        this.totalProvided = approvals.totalProvided
        for(let item of approvals.provided){
          item.status = 'provided'
          this.approvals.push(item)
        }
        for(let item of approvals.requested){
          item.status = 'requested'
          this.approvals.push(item)
        }
        await this.getBps()
        this.getAcceptedTop21()
      }catch(e){}
      this.loading = false
    },

    async getBps(){
      let t:any = await Global.getBlokProducers();
      this.bps = t.value
    },

    getAcceptedTop21(){
      for(let bp of this.approvals){
        let index:number = this.bps.findIndex((producer) => producer.owner == bp.actor)
        if(index < 21 && index >= 0 && bp.status == "provided"){
          this.acceptedTop21.push(bp);
        }
      }
    },

    isTop21(bp:any){
      let index:number = this.bps.findIndex((producer) => producer.owner == bp.actor) 
      if(index < 21 && index >= 0){
        return true
      }else{
        return false
      }
    }
  },

  mounted(){
    this.init();
  },


});
