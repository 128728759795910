
import { defineComponent } from "vue";
//components
//services
import GlobalServices from '@/services/GlobalServices'
//models
export default defineComponent({
  name: "proposal-info",

  components:{

  },
  props: {
    proposer: String,
    proposalName: String,
  },

  data(){
    return{
      loading: false
    }
  },

  methods:{
  }

});
