
import { defineComponent } from "vue";
import {mapState} from "vuex"
import { useToast } from "vue-toastification";

//components
import Loading from "@/components/loading/Loading.vue"
import ActionAccor from "@/pages/proposal/components/ActionAccor.vue"
import PersonalInfoAccor from "@/pages/proposal/components/PersonalInfoAccor.vue"
import ProposalReviewAccor from "@/pages/proposal/components/ProposalReviewAccor.vue"
import SettingAccor from "@//pages/proposal/components/SettingAccor.vue"
//services
import AccountService from "@/services/AccountServices";
import GlobalServices from "@/services/GlobalServices"
import MultisigsServices from "@/services/MultisigsServices";
//models
import Statistics from "@/models/statistics_Models/statisticsModel";
import TransactionServices from "@/services/TransactionServices";
export default defineComponent({
  name: "copy-multisig",
  props:["actions"],
  components:{
    Loading,
    ActionAccor,
    PersonalInfoAccor,
    ProposalReviewAccor,
    SettingAccor
  },

  data(){
    const toast = useToast();
    let account:any = {}
    let actionsList:any[] = []
    let infoData:any = {}
    let settingBlockInfo:any = {}
    let settingData:any = {}
    return{
      loading: true,
      toast,
      account,
      actionsList,
      infoData,
      settingBlockInfo,
      settingData,
      counter:0,
      loadingPropose:'off'
    }
  },
  
  methods:{
    async init(){
      this.loading = true
      try{
        this.account = ( await AccountService.getSelectedAccount() ).data
        this.actionsList = JSON.parse(JSON.stringify(this.actions.actions))
        // let x:Statistics = await GlobalServices.getStatics()
        await this.getBlock()
        this.randomName()
        // await MultisigsServices.unpack()
      }catch(e){}
      this.loading = false
    },

    returnProposal(){
      this.$emit("returnProposal" , false)
    },

    setAction(data:any){
      this.actionsList[data.index] = data.action
      this.addCounter()
    },

    removeAction(index){
      this.loading = true
      this.actionsList.splice(index , 1)      
      this.addCounter()
      setTimeout(() => {
        this.loading = false
      } , 10)
    },

    addAction(data){
      this.loading = true
      this.actionsList.splice(data.index , 0 , data.action)      
      this.addCounter()
      setTimeout(() => {
        this.loading = false
      } , 10)
    },

    setPersonalInfo(data){
      this.infoData = data
      this.addCounter()
    },

    setSetting(data){
      this.settingData = data
      this.addCounter()
    },

    async getBlock(){
      let blockInfo = await GlobalServices.getInfo()
      let sendBLockNum = blockInfo.head_block_num - 12
      let prefixBlock = await TransactionServices.getBlock(sendBLockNum)
      this.settingBlockInfo = {
        ref_block_prefix: prefixBlock.refBlockPrefix,
        ref_block_num: sendBLockNum & 65535
      }
    },

    async propose(){
      this.loadingPropose = 'on'
      let actions:any[] = []
      for(let action of this.actionsList){
        try{
          let t:any =  await MultisigsServices.packAction([action])
          actions.push(t)
        }catch(e){
          console.log(e);
          this.toast.error(`${e}`)
          return
        }
      }
      
      let trxData:any = this.settingData
      trxData.actions = actions
      try{
        await MultisigsServices.propose(
          this.account.accountName,
          this.account.permission,
          this.infoData,
          trxData
        )
      }catch(e){
        this.toast.error(`${e}`)
      }
      this.loadingPropose = 'off'
    },

    randomName(){
      const rnd = (len, chars='abcdefghijklmnopqrstuvwxyz012345') => [...Array(len)].map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('')
      this.infoData.proposalName = rnd(6)
    },

    addCounter(){
      this.counter++
    }
    
  },

  mounted(){
    this.init()
  },


});
